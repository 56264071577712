<template>
	<!-- <div class="banner">
		<img alt="Banner" src="/banner-motul-web.jpg"/>
	</div> -->
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">

			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;WELCOME <span class="uppercase"><u>{{user?.userweb_name}}({{user?.roles?.name}})</u></span> TO</div>
					<div class="text-900 font-bold text-5xl mb-3">{{user?.company?.company_name}} - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,
		}
	},
	mounted() {
		if (localStorage.getItem("authToken")) {
			this.getUserData();
		}
	},
	computed: {
		...mapGetters("auth", ["user"]),
	},
    methods: {
		...mapActions("auth", ["getUserData"]),
    },
}
</script>
<style>
	.banner {
		width: 100%;
		/* height: 200px; */
		background-color: #f0f0f0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.banner img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}
</style>